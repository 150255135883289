html,
body {
  font-family: 'BwAletaReg';
  background: url("https://static.wixstatic.com/media/181739_b30a2d4096054278be59319de9918d5b~mv2.png");
}

.header-bg {
  background: url("https://static.wixstatic.com/media/181739_8bc6cb1b9cea4aff955534092cb80b14~mv2.png");
}

.header-bg-h {
  height: 90px;
}

.mt15 {
  margin-top: 6rem;
}

.footer-bg {
  background: url("https://static.wixstatic.com/media/181739_19ed5ae87b0a4daa91f12f6a5491118e~mv2.png");
  background-repeat: no-repeat;
}

.footer-h {
  height: 299px;
}

.m-auto {
  margin: 0 auto;
}

/* Success page */
.success-main {
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  align-items: center;
  justify-content: center;
}

.success-img {
  height: 160px;
}

.success-h1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.008em;
}

.success-p {
  font-size: 14px;
  letter-spacing: -0.005em;
  line-height: 20px;
}
/* Succes page end */

.google-btn {
  background-color: #1197d3;
  box-shadow: 0px 14px 29px rgba(4, 116, 178, 0.12);
  border-radius: 4px;
}

.google-btn span {
  color: #ffffff;
}

.plans {
  display: inline-grid;
  text-align: center;
  border-radius: 5px;
  min-width: 250px;
  padding: 5px;
  color: #FFFFFF;
  font-size: 18px;
}

@media screen and (max-width: 415px) {
  .mt15 {
    margin-top: 7rem;
  }
}

@media screen and (min-width: 380px) {
  .lead {
    font-size: 2.25rem;
  }
}

@font-face {
  font-family: "BwAleta";
  src: local("fonts"),
   url("./fonts/BwAletaNo20-Black.ttf") format("truetype");
}

@font-face {
  font-family: "BwAletaReg";
  src: local("fonts"),
   url("./fonts/BwAletaNo20-Regular.ttf") format("truetype");
}
