
.tryfree {
  font-size: 50px !important;
}
.trialtext {
  font-size: 20px !important;
}

.tryfree,
.trialtext {
  color: #323f4e !important;
  font-weight: bold !important;
}
.trial {
  color: #394149 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.headname {
  margin: 0 !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.getstrt {
  margin: 0px !important;
  margin-bottom: 4% !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}

.card-group-center {
  margin-left: 28%;
  margin-right: 28%;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Plans Section
--------------------------------------------------------------*/
#plan-section {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("https://static.wixstatic.com/media/181739_388982ce4e714056bc9db6ecca21555e~mv2.png") top center;
  background-size: cover;
  position: relative;
}

.card-header {
  background: #0474b2;
}

#plan-section:before {
  content: "";

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05;
}

#plan-section .container {
  padding-top: 30px;
}

.trialButtons {
  flex-direction: column;
  max-width: 250px;
}

#plan-section h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
  font-family: "Poppins", sans-serif;
}

#plan-section h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
}

#plan-section .btn-get-started {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 50px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #0474b2;
  background: #ffffff;
  border: 1px solid;
}

#plan-section .btn-get-started:hover {
  background: #3194db;
}

#plan-section .icon-boxes {
  margin-top: 100px;
}

#plan-section .icon-box {
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

#plan-section .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#plan-section .icon-box .title a {
  color: #124265;
  transition: 0.3s;
}

#plan-section .icon-box .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.imgfloat {
  float: right !important;
}

.hed {
  background-color: #26b8ea !important;
}
.crdd {
  border: 2px solid #26b8ea !important;
}

.crdmov {
  margin-right: 2% !important;
}

#plan-section .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: #2487ce;
}

.crdbg {
  background: transparent;
  border: none;
}

.q1 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #394149 !important;
}
.ans {
  color: #4a555f;
  font-weight: 500;
  font-size: 16px;
}

.toptxt {
  color: #323f4e !important;
  font-size: 30px !important;
  font-weight: 500 !important;
}

.titlenum {
  font-size: 18px !important;
  color: #394149 !important;
  font-weight: bold !important;
}
.para {
  color: #4a555f !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

#plan-section .icon-box:hover {
  transform: scale(1.08);
}

.line {
  width: 10%;
  background-color: #1197d3;
  height: 1px;
  margin-top: 0%;
}

#plan-section .icon-box:hover .title a {
  color: #2487ce;
}

@media (min-width: 1000px) {
  #plan-section {
    background-attachment: fixed;
    margin-top: 5%;
    height: auto;
  }
  .boldtext {
    color: #ffffff !important;
  }
}

@media (max-width: 999px) {
  #plan-section {
    height: auto;
  }
}

@media (max-width: 992px) {
  #plan-section .btn-get-started {
    padding: 7px 33px !important;
  }
  .line {
    margin-top: 2% !important;
  }
  .boldtext {
    color: #ffffff !important;
  }
}

@media (max-width: 992px), (max-height: 500) {
  .card p {
    color: #ffffff !important;
    font-size: 14px !important;

    margin: 0px !important;
  }
  .card h2 {
    color: #ffffff !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin: 0px !important;
  }

  .promoting-card img {
    width: 100px;
    height: 100px;
  }
  .promoting-card p {
    font-size: 10px;
    color: #4a555f !important;
  }
  .promoting-card h4 {
    font-size: 14px;
    color: #394149;
  }

  .toptxt {
    font-size: 20px;
  }
  .q1 {
    font-size: 14px;
  }
  .ans {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .card-group {
    display: inline !important;
  }
  .card {
    margin-bottom: 2% !important;
  }

  .avt {
    display: inherit;
  }

  .ques {
    display: inherit;
  }
  .promoting-card img {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 992px), (max-height: 500) {
  #plan-section {
    height: auto;
  }
  #plan-section h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #plan-section h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

.boldtext {
  color: #ffffff !important;
  /* font-size: 30px !important; */
  font-weight: bold !important;
  margin: 0px !important;
}

.card-block li {
  color: #4a555f !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

section {
  padding: 80px 0;
}

.checkAddOn {
  right: 30%;
  top: 15%;
}

.check {
  width: 30px;
  height: 15px;
}
